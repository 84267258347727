import { components } from '@/lib/helpers.js'

//
// Fonts
//
import '@fontsource-variable/asap'

//
// Styles
//
import '../css/style.css'

//
// Initialize components
//
components({
    header: import('@/components/header.js'),
    'embed-video': import('@/components/embed/video.js'),
})
